import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Cambodia",
  "subtitle": "October 2018",
  "category": "Asia"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.grab.com/kh/en/"
        }}>{`GRAB`}</a>{` - Basically Uber`}</li>
      <li parentName="ul">{`Almost all vendors preferred USD. The exchange rate varied at every shop.`}</li>
      <li parentName="ul">{`Local SIM - Bought one at the airport`}</li>
    </ul>
    <h3 {...{
      "id": "shopping"
    }}>{`Shopping`}</h3>
    <ul>
      <li parentName="ul">{`Don’t forget to bargain! It’s never the market price. At the time, it was around`}
        <ul parentName="li">
          <li parentName="ul">{`$2 for scarves`}</li>
          <li parentName="ul">{`$1 for 10 thin bamboo bracelets`}</li>
          <li parentName="ul">{`$2 for 10 wider bamboo bracelets`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Fairtrade, no bargains but also much higher quality goods`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/pkWALDRAh82YMxbn6"
            }}>{`Artisans Angkor`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/mGvSBk3Vh2nuZwZN7"
            }}>{`Watthan`}</a></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "siem-reap"
    }}>{`Siem Reap`}</h2>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/osp9nfijS7vv39Nd7"
        }}>{`Gelato Lab`}</a>{` - Ice cream around pub area`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/UsHWPbTQXvawQJwx9"
        }}>{`Khmer Kitchen`}</a>{` -`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/6GeQJrkXRYrGYNVL7"
        }}>{`Night market`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/dxqgiSSLZCwauyeq8"
        }}>{`Pub street`}</a>{` - Lots of food, tuk-tuks, shopping, etc`}</li>
    </ul>
    <h4 {...{
      "id": "kompong-khleang-floating-village"
    }}>{`Kompong Khleang floating village`}</h4>
    <ul>
      <li parentName="ul">{`Highly recommend the eco-tour with `}<a parentName="li" {...{
          "href": "https://kompongkhleang.org/"
        }}>{`Community First`}</a></li>
      <li parentName="ul">{`Profits go to the school they built`}</li>
    </ul>
    <h4 {...{
      "id": "angkor-wat"
    }}>{`Angkor Wat`}</h4>
    <ul>
      <li parentName="ul">{`Must be guided so I did a small circuit private tour. Entrance fee not included!`}</li>
      <li parentName="ul">{`Ticket required a photo so smile!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/9eKA3ijMfKaej9Xp9"
        }}>{`Angkor Thom`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/gXgDdQyk83eTy5g3A"
        }}>{`Angkor Wat`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/kXR9jiNCZM6ExcuV8"
        }}>{`Bayon Temple`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/iY9GcjQV2uNRsE1S9"
        }}>{`Prasat Preah Khan`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/MhPehgM3oeoua54G9"
        }}>{`Ta Prohm Templ`}</a>{` - Famous for Tomb Raider`}</li>
    </ul>
    <h2 {...{
      "id": "phnom-penh"
    }}>{`Phnom Penh`}</h2>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/uigf4VNygJdvtwVh9"
        }}>{`JJ Ice Cream`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/r3gypr739vvZDn7K6"
        }}>{`Tuol Sleng Genocide Museum`}</a>{` - It's outdoors so might want to bring some mosquito spray. I recommend the audio guide.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/YEWcKuPbYBQKmh7C9"
        }}>{`Cambodian Living Arts performance`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Performance was at the `}<a parentName="li" {...{
              "href": "https://maps.app.goo.gl/weydaowpsH669bjk6"
            }}>{`National Museum of Cambodia`}</a></li>
          <li parentName="ul">{`First row of the 2nd tier offered the best views for value (in my opinion)`}</li>
          <li parentName="ul">{`Read the pamphlet for the story`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/D2zwhhSufAcL8CAB8"
        }}>{`AEON Mall (Sen Sok City)`}</a>{` - Massive modern mall on the outskirt of the city`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      